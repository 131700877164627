import { Environment } from './environment.model';

export const environment: Environment = {
  apiUrl: 'https://my.enerix.de/api/',
  appleLogin:
    'https://enerix-production.auth.eu-central-1.amazoncognito.com/oauth2/authorize?identity_provider=SignInWithApple&redirect_uri=https://my.enerix.de/login&response_type=CODE&client_id=5lf3ojcrqtj84m2o44lqboro37&scope=aws.cognito.signin.user.admin%20openid',
  googleLogin:
    'https://enerix-production.auth.eu-central-1.amazoncognito.com/oauth2/authorize?identity_provider=Google&redirect_uri=https://my.enerix.de/login&response_type=CODE&client_id=5lf3ojcrqtj84m2o44lqboro37&scope=aws.cognito.signin.user.admin%20openid',
  hubspot: {
    portalId: '144047131',
  },
};
